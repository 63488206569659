export default (state = null, action) => {
  if (null === state ) {
    state = false
  }

  switch (action.type) {
    case 'DISPLAY_LOADING_ICON':
      state = action.payload
      break
    default:
      return state
  }
  return state
}