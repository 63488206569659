export default (state = [], action) => {
  switch (action.type) {
    case 'DID_CHANGE_DEALERSHIP':
    case 'DID_CHANGE_DATE':
    case 'DID_CHANGE_LOG_REPORT_TYPE':
      state = []
      break
    case 'DID_UPDATE_LOGS':
      state = [...state, ...action.payload]
      break
    default:
      return state
  }
  return state
}