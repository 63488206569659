export default (state = null, action) => {
  const defaults = {
    type: '',
    data: []
  }
  if (null === state ) {
    state = defaults
  }

  switch (action.type) {
    case 'DID_CHANGE_DATE':
      state = defaults
      break
    case 'DID_UPDATE_REPORT':
      state = {...state, ...action.payload}
      break
    default:
      return state
  }
  return state
}