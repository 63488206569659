import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import 'react-day-picker/lib/style.css'
import Select from '../components/select'
import Loader from '../components/loader'

class EntryProduct extends Component {

  constructor(props) {
    super(props)
    this.state = {
      can_submit_form: false,
      is_submitting_form: false,
      txn_date: new Date(),
      txn_type: 'product',
      customer_first: '',
      customer_last: '',
      finance_manager: '_',
      sales_manager: '_',
      salesperson_1: '_',
      salesperson_2: '_',
      vehicle_year: new Date().getFullYear(),
      vehicle_make: '',
      vehicle_model: '',
      vehicle_stock_number: '',
      vehicle_txn_type: 'New',
      gap: '',
      etching: '',
      eckbond: '',
      service_contracts: '',
      appearance_protection: '',
      tire_wheel: '',
      key_replacement: '',
      custom_product_1_price: '',
      custom_product_2_price: '',
      comments: ''
    }
    this.changeFinanceManager = this.changeFinanceManager.bind(this)
    this.changeSalesManager = this.changeSalesManager.bind(this)
    this.changeSalesperson1 = this.changeSalesperson1.bind(this)
    this.changeSalesperson2 = this.changeSalesperson2.bind(this)
    this.changeVehicleTxnType = this.changeVehicleTxnType.bind(this)
    this.changeVehicleYear = this.changeVehicleYear.bind(this)
    this.handleNumericInput = this.handleNumericInput.bind(this)
    this.handleTextInput = this.handleTextInput.bind(this)
    this.handleTxnDayClick = this.handleTxnDayClick.bind(this)
    this.maybeEnableSubmitForm = this.maybeEnableSubmitForm.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  changeBDC(val) {
    this.setState({
      bdc: val
    }, this.maybeEnableSubmitForm)
  }

  changeDealLender(val) {
    this.setState({
      deal_lender: val
    }, this.maybeEnableSubmitForm)
  }

  changeDealType(val) {
    this.setState({
      deal_type: val
    }, this.maybeEnableSubmitForm)
  }

  changeFinanceManager(val) {
    this.setState({
      finance_manager: val
    }, this.maybeEnableSubmitForm)
  }

  changeSalesManager(val) {
    this.setState({
      sales_manager: val
    }, this.maybeEnableSubmitForm)
  }

  changeTradeInPaymentType(val) {
    this.setState({
      tradein_payment_type: val
    }, this.maybeEnableSubmitForm)
  }

  changeTradeInYear(val) {
    this.setState({
      tradein_year: parseInt(val, 10)
    }, this.maybeEnableSubmitForm)
  }

  changeSalesperson1(val) {
    this.setState({
      salesperson_1: val
    }, this.maybeEnableSubmitForm)
  }

  changeSalesperson2(val) {
    this.setState({
      salesperson_2: val
    }, this.maybeEnableSubmitForm)
  }

  changeVehicleTxnType(val) {
    this.setState({
      vehicle_txn_type: val
    }, this.maybeEnableSubmitForm)
  }

  changeVehicleYear(val) {
    this.setState({
      vehicle_year: parseInt(val, 10)
    }, this.maybeEnableSubmitForm)
  }

  handleNumericInput(e) {
    const val = e.target.value.trimLeft()
    if (false === isNaN(val)) {
      this.setState({
        [e.target.name]: val
      }, this.maybeEnableSubmitForm)
    }
  }

  handleTextInput(e) {
    const val = e.target.value.trimLeft()
    this.setState({
      [e.target.name]: val
    }, this.maybeEnableSubmitForm)
  }

  handleTxnDayClick(selectedDay) {
    this.setState({
      txn_date: selectedDay
    }, this.maybeEnableSubmitForm)
  }

  handleTradeInDeliveryClick(selectedDay) {
    this.setState({
      tradein_delivery: selectedDay
    }, this.maybeEnableSubmitForm)
  }

  maybeEnableSubmitForm() {
    const form_is_incomplete =  '' === this.state.txn_date
                                || '_' === this.state.finance_manager
                                || '_' === this.state.sales_manager
                                || '_' === this.state.salesperson_1
                                || '' === this.state.vehicle_make
                                || '' === this.state.vehicle_model

    this.setState({
      can_submit_form: !form_is_incomplete,
    })
  }

  submitForm() {
    this.setState({
      can_submit_form: false,
      is_submitting_form: true
    }, () => {
      axios.post(`${process.env.REACT_APP_WP_REST_API}/deal`, {...{
        dealership: this.props.user.dealership,
        token: this.props.user.token
      }, ...this.state})
      .then((response) => {
        this.props.history.push('/')
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
    })
  }

  render() {
    let form
    let description
    if ('all' === this.props.dealerships.length) {
      description = (<p>You are logged into multiple dealerships. Please select a dealership to continue.</p>)
      form = (<p><Link to="/dealerships">Click here to select a dealership</Link>.</p>)
    } else {
      let changeDealership
      let dealership = {
        id: '',
        name: '',
        finance_managers: [],
        sales_managers: [],
        salespeople: [],
        bdc: [],
        lenders: [],
        deal_types: []
      }
      if (1 < this.props.dealerships.length) {
        changeDealership = (<span><Link to="/dealerships">Click here to change dealerships</Link>.</span>)
      }
      for (let i = 0; i < this.props.dealerships.length; i++) {
        if (this.props.user.dealership === this.props.dealerships[i].id) {
          dealership = this.props.dealerships[i]
        }
      }
      description = (<p>Use this form to add a product deal at <strong>{dealership.name}</strong>. {changeDealership}</p>)
      const dateFormat = 'M/D/YYYY'
      let yearList = []
      for (let i = 0; i < 50; i++) {
        const year = new Date().getFullYear() + 3 - i
        // yearList[year] = year
        yearList.push(year)
      }
      let finance_managers = {'_' : 'Select One'}
      if (0 < dealership.finance_managers.length) {
        for (let i = 0; i < dealership.finance_managers.length; i++) {
          finance_managers[dealership.finance_managers[i].id] = dealership.finance_managers[i].name
        }
      }
      let sales_managers = {'_' : 'Select One'}
      if (0 < dealership.sales_managers.length) {
        for (let i = 0; i < dealership.sales_managers.length; i++) {
          sales_managers[dealership.sales_managers[i].id] = dealership.sales_managers[i].name
        }
      }
      let salesperson_1 = {'_' : 'Select One'}
      let salesperson_2 = {'_' : 'Select One'}
      if (0 < dealership.salespeople.length) {
        for (let i = 0; i < dealership.salespeople.length; i++) {
          salesperson_1[dealership.salespeople[i].id] = dealership.salespeople[i].name
          salesperson_2[dealership.salespeople[i].id] = dealership.salespeople[i].name
        }
      }

      if (true === this.state.is_submitting_form) {
        form = (<Loader />)
      } else {
        form = (
          <div className="form">
            <h2>Deal Information</h2>
            <div className="form-row">
              <div className="form-column form-column-33">
                <label>Deal/Transaction Date <span className="required">*</span></label>
                <DayPickerInput
                  formatDate={format}
                  format={dateFormat}
                  parseDate={parse}
                  placeholder="Transaction Date"
                  dayPickerProps={{
                    disabledDays: [{
                      after: new Date()
                    }],
                    month: new Date(),
                    selectedDays: this.state.txn_date,
                    onDayClick: this.handleTxnDayClick,
                    toMonth: new Date(),
                    showOutsideDays: true,
                    todayButton: 'Today',
                  }}
                  value={this.state.txn_date}
                />
              </div>
              <div className="form-column form-column-33">
                <label>Customer First Name</label>
                <input type="text" name="customer_first" onChange={this.handleTextInput} value={this.state.customer_first} />
              </div>
              <div className="form-column form-column-33">
                <label>Customer Last Name</label>
                <input type="text" name="customer_last" onChange={this.handleTextInput} value={this.state.customer_last} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-50">
                <label>Finance Manager <span className="required">*</span></label>
                <Select
                  onChange={this.changeFinanceManager}
                  options={finance_managers}
                  value={this.state.finance_manager}
                />
              </div>
              <div className="form-column form-column-50">
                <label>Sales Manager <span className="required">*</span></label>
                <Select
                  onChange={this.changeSalesManager}
                  options={sales_managers}
                  value={this.state.sales_manager}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-50">
                <label>Salesperson <span className="required">*</span></label>
                <Select
                  onChange={this.changeSalesperson1}
                  options={salesperson_1}
                  value={this.state.salesperson_1}
                />
              </div>
              <div className="form-column form-column-50">
                <label>Add&#8217;l Salesperson</label>
                <Select
                  onChange={this.changeSalesperson2}
                  options={salesperson_2}
                  value={this.state.salesperson_2}
                />
              </div>
            </div>
            <h2>Vehicle Information</h2>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>Year <span className="required">*</span></label>
                <Select
                  onChange={this.changeVehicleYear}
                  options={yearList}
                  value={this.state.vehicle_year}
                />
              </div>
              <div className="form-column form-column-25">
                <label>Make <span className="required">*</span></label>
                <input type="text" name="vehicle_make" onChange={this.handleTextInput} value={this.state.vehicle_make} />
              </div>
              <div className="form-column form-column-25">
                <label>Model <span className="required">*</span></label>
                <input type="text" name="vehicle_model" onChange={this.handleTextInput} value={this.state.vehicle_model} />
              </div>
              <div className="form-column form-column-25">
                <label>Vehicle Type <span className="required">*</span></label>
                <Select
                  onChange={this.changeVehicleTxnType}
                  options={['New', 'Used', 'Lease']}
                  value={this.state.vehicle_txn_type}
                />
              </div>
            </div>
            <h2>Deal Details</h2>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>GAP</label>
                <input type="text" name="gap" onChange={this.handleNumericInput} value={this.state.gap} />
              </div>
              <div className="form-column form-column-25">
                <label>Service Contracts</label>
                <input type="text" name="service_contracts" onChange={this.handleNumericInput} value={this.state.service_contracts} />
              </div>
              <div className="form-column form-column-25">
                <label>Appearance Protection</label>
                <input type="text" name="appearance_protection" onChange={this.handleTextInput} value={this.state.appearance_protection} />
              </div>
              <div className="form-column form-column-25">
                <label>Tire &amp; Wheel</label>
                <input type="text" name="tire_wheel" onChange={this.handleNumericInput} value={this.state.tire_wheel} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>Key Replacement</label>
                <input type="text" name="key_replacement" onChange={this.handleNumericInput} value={this.state.key_replacement} />
              </div>
              <div className="form-column form-column-25">
                <label>Etching</label>
                <input type="text" name="etching" onChange={this.handleNumericInput} value={this.state.etching} />
              </div>
              <div className="form-column form-column-25">
                <label>Eckbond</label>
                <input type="text" name="eckbond" onChange={this.handleNumericInput} value={this.state.eckbond} />
              </div>
              <div className="form-column form-column-25">
                <label>Custom Product</label>
                <input type="text" name="custom_product_1_price" onChange={this.handleNumericInput} value={this.state.custom_product_1_price} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>Custom Product</label>
                <input type="text" name="custom_product_2_price" onChange={this.handleTextInput} value={this.state.custom_product_2_price} />
              </div>
              <div className="form-column form-column-50">
                <label>Add&#8217;l Comments</label>
                <input type="text" name="comments" onChange={this.handleTextInput} value={this.state.comments} />
              </div>
            </div>
            <p><button type="button" className="button-primary" onClick={this.submitForm} disabled={!this.state.can_submit_form}>Submit</button></p>
          </div>
        )
      }
    }

    return (
      <div id="entry-form-container">
        <section className="page-header">
          <h1>Product Entry</h1>
          {description}
        </section>
        {form}
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    deal: state.deal,
    dealerships: state.dealerships,
    people: state.people,
    user: state.user
  }
}

export default withRouter(connect(mapStateToProps)(EntryProduct))
