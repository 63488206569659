import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Switch, Redirect, Route, withRouter } from 'react-router-dom'
import { tryLogin } from '../actions'
import HTMLInput from '../components/html-input'
import Header from '../components/header'
import Loader from '../components/loader'
import Dealerships from './dealerships'
import EntryProduct from './entry-product'
import EntryVehicle from './entry-vehicle'
import Logs from './logs'
import Report from './report'
import Reports from './reports'

const copyright = new Date().getFullYear()
const logo = require('../../images/logo.png')

class Auth extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      isButtonEnabled: false,
      password: '',
      username: '',
    }
    this.signin = this.signin.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.maybeEnableButton = this.maybeEnableButton.bind(this)
  }

  componentDidMount() {
    if (false === !!this.props.user.token && '/' !== this.props.location.pathname) {
      this.props.history.push('/')
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      isButtonEnabled: !nextProps.user.isLoggingIn,
    }
  }

  handleInputChange(val, key) {
    this.setState({
      [key]: val
    }, this.maybeEnableButton)
  }

  maybeEnableButton() {
    this.setState({
      isButtonEnabled: this.state.username.length && this.state.password.length
    })
  }

  handleInput(e) {
    const key = e.target.name
    const newState = {
      hasError: false,
      [key]: e.target.value.trimLeft()
    }
    this.setState(newState)
  }

  signin() {
    this.props.tryLogin(this.state.username, this.state.password)
  }

  render() {
    if (false === !!this.props.user.token) {
      return (
        <div id="login">
          <div id="login-box">
            <img src={logo} width="66" height="55" alt="Go Profit Path" />
            {true === this.props.user.hasLoginError &&
              (<div className="error">Invalid email or password</div>)
            }
            <HTMLInput
              autocomplete="email"
              label="Email Address"
              map="username"
              required={true}
              onChange={this.handleInputChange}
              value={this.state.username}
            />
            <HTMLInput
              autocomplete="current-password"
              label="Password"
              map="password"
              onChange={this.handleInputChange}
              required={true}
              type="password"
              value={this.state.password}
            />
            <button type="button" className="button-primary" disabled={!this.state.isButtonEnabled} onClick={this.signin}>Sign In</button>
          </div>
        </div>
      )
    } else {
      const content = this.props.user.isLoggingIn ? (
        <Loader />
        ) : (
          <Switch>
            <Route exact path="/" component={Reports} />
            <Route exact path="/entry/vehicle" component={EntryVehicle} />
            <Route exact path="/entry/product" component={EntryProduct} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/reports/:type" component={Report} />
            <Route exact path="/logs" component={Logs} />
            <Route exact path="/dealerships" component={Dealerships} />
            <Redirect to="/" />
          </Switch>
        )
      return (
        <div id="wrapper-app">
          <Header />
          <div id="app-inner" className="container">
            {content}
          </div>
          <footer className="site-footer">
            &copy;{copyright}. Dealer Systems Associates. All Rights Reserved.
          </footer>
        </div>
      )
    }
  }

}

function mapStateToProps(state) {
  return {
    loading: state.loading,
    user: state.user
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    tryLogin: tryLogin
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Auth))
