import axios from 'axios'
import format from 'date-fns/format'

export const doGetLogs = () => {
  return (dispatch, getState) => {
    dispatch({
      payload: true,
      type: 'DISPLAY_LOADING_ICON'
    })
    const end = format(getState().user.endDate, 'YYYY-MM-DD')
    const start = format(getState().user.startDate, 'YYYY-MM-DD')
    console.log(start, end, getState().user.dealership, getState().user.token)
    return axios.get(`${process.env.REACT_APP_WP_REST_API}/logs`, {
      params: {
        dealership: getState().user.dealership,
        end: end,
        start: start,
        token: getState().user.token,
        type: getState().user.logDealType
      }
    })
    .then((response) => {
      dispatch({
        payload: response.data,
        type: 'DID_UPDATE_LOGS'
      })
      dispatch({
        payload: false,
        type: 'DISPLAY_LOADING_ICON'
      })
    })
    .catch((error) => {
      dispatch({
        payload: false,
        type: 'DISPLAY_LOADING_ICON'
      })
      dispatch({
        payload: {},
        type: 'LOG_OUT'
      })
    })
  }
}

export const didUpdateLogs = (data) => {
  return {
    payload: data,
    type: 'DID_UPDATE_LOGS'
  }
}