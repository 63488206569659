import axios from 'axios'
import { doGetDealerships } from './dealerships'
import { doGetLogs } from './logs'
import { getUpdatedReport } from './reports'

export const didChangeDealership = (data) => {
  return (dispatch, getState) => {
    dispatch({
      payload: {'dealership': data},
      type: 'DID_CHANGE_DEALERSHIP'
    })
    dispatch(doGetLogs())
    dispatch(getUpdatedReport())
  }
}

export const didChangeDate = (data) => {
  return (dispatch, getState) => {
    dispatch({
      payload: data,
      type: 'DID_CHANGE_DATE'
    })
    dispatch(doGetLogs())
  }
  // return {
  //   payload: data,
  //   type: 'DID_CHANGE_DATE'
  // }
}

export const didChangeLogReportType = (data) => {
  return (dispatch, getState) => {
    dispatch({
      payload: {'logDealType': data},
      type: 'DID_CHANGE_LOG_REPORT_TYPE'
    })
    dispatch(doGetLogs())
  }
  // return {
  //   payload: {'logDealType': data},
  //   type: 'DID_CHANGE_LOG_REPORT_TYPE'
  // }
}

export const doLogOut = () => {
  return (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_WP_REST_API}/logout`, {
      token: getState().user.token
    })
    dispatch({
      payload: {},
      type: 'LOG_OUT'
    })
  }
}

export const tryLogin = (username, password) => {
  return (dispatch, getState) => {
    dispatch({
      payload: {hasLoginError: false, isLoggingIn: true},
      type: 'IS_LOGGING_IN'
    })
    return axios.post(`${process.env.REACT_APP_WP_REST_API}/login`, {
      username: username.trim(),
      password: password.trim()
    })
    .then((response) => {
      dispatch({
        payload: {...response.data, ...{hasLoginError: false, isLoggingIn: false}},
        type: 'LOG_IN'
      })
      dispatch(doGetDealerships())
    })
    .catch((error) => {
      dispatch({
        payload: {},
        type: 'LOG_OUT'
      })
      dispatch({
        payload: {hasLoginError: true, isLoggingIn: false},
        type: 'LOG_IN_WITH_ERROR'
      })
    })
  }
}