import { combineReducers } from 'redux'
import Dealerships from './dealerships'
import Deals from './deals'
import Loading from './loading'
import Logs from './logs'
import Reports from './reports'
import User from './user'

const appReducer = combineReducers({
  dealerships: Dealerships,
  deal: Deals,
  loading: Loading,
  logs: Logs,
  reports: Reports,
  user: User
})

export default (state, action) => {
  if ('LOG_OUT' === action.type) {
    state = {}
  }
  return appReducer(state, action)
}