import axios from 'axios'
import format from 'date-fns/format'

export const getUpdatedReport = (type = 'general', personSelected = 'all') => {
  return (dispatch, getState) => {
    const dealership = getState().user.dealership
    const end = format(getState().user.endDate, 'YYYY-MM-DD')
    const start = format(getState().user.startDate, 'YYYY-MM-DD')
    const token = getState().user.token
    dispatch({
      type: 'DISPLAY_LOADING_ICON',
      payload: true
    })
    axios.get(`${process.env.REACT_APP_WP_REST_API}/reports`, {
      params: {
        dealership: dealership,
        end: end,
        person: personSelected,
        start: start,
        token: token,
        type: type
      }
    })
    .then((response) => {
      dispatch({
        type: 'DID_UPDATE_REPORT',
        payload: response.data
      })
      dispatch({
        type: 'DISPLAY_LOADING_ICON',
        payload: false
      })
    })
    .catch((error) => {
      dispatch({
        type: 'DISPLAY_LOADING_ICON',
        payload: false
      })
      console.log('Error: ', error)
      dispatch({
        type: 'LOG_OUT',
        payload: {}
      })
    })
  }
}