import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { didChangeDealership } from '../actions'
import Select from '../components/select'

class Dealerships extends Component {

  constructor(props) {
    super(props)
    this.changeDealership = this.changeDealership.bind(this)
  }

  changeDealership(dealership) {
    this.props.didChangeDealership(dealership)
  }

  render() {

    const dealerships = this.props.dealerships.map((dealership) => {
      return {
        [dealership.id]: dealership.name
      }
    })
    dealerships.unshift({
      all: 'All Dealerships'
    })

    return (
      <div id="logs">
        <section className="page-header">
          <h1>My Dealerships</h1>
          <p>Select a dealership below to change which logs and reports you are viewing. To add a new dealership or to add/edit people in your current dealerships, <a href={process.env.REACT_APP_WP}>click here to login to the admin dashboard</a>.</p>
        </section>
        <Select
          onChange={this.changeDealership}
          options={dealerships}
          value={this.props.user.dealership}
        />
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    dealerships: state.dealerships,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    didChangeDealership: didChangeDealership
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dealerships)
