import axios from 'axios'
import { doGetLogs } from './logs'
import { getUpdatedReport } from './reports'

export const doGetDealerships = () => {
  return (dispatch, getState) => {
    dispatch({
      payload: true,
      type: 'DISPLAY_LOADING_ICON'
    })
    return axios.get(`${process.env.REACT_APP_WP_REST_API}/dealerships`, {
      params: {
        token: getState().user.token
      }
    })
    .then((response) => {
      dispatch({
        payload: response.data,
        type: 'DID_GET_DEALERSHIPS'
      })
      dispatch(doGetLogs())
      dispatch(getUpdatedReport())
      dispatch({
        payload: false,
        type: 'DISPLAY_LOADING_ICON'
      })
    })
    .catch((error) => {
      dispatch({
        payload: {},
        type: 'LOG_OUT'
      })
    })
  }
}