import React, { PureComponent } from 'react'

export default class ReportDetailed extends PureComponent {

  constructor() {
    super()
    this.doPercentage = this.doPercentage.bind(this)
  }

  doPercentage(numerator, denomenator) {
    if (isNaN(denomenator) || 0 === denomenator) {
      return '-'
    }
    const percentage = parseFloat(100 * numerator / denomenator).toFixed(1)
    return `${percentage}%`
  }

  render() {
    if (-1 === this.props.type.search('detailed')) {
      return null
    }
    let data = this.props.data

    for (let vehicleType in data) {
      for (let groupType in data[vehicleType]) {
        for (let attribute in data[vehicleType][groupType]) {
          if (isNaN(data[vehicleType][groupType][attribute]) || null === data[vehicleType][groupType][attribute]) {
            data[vehicleType][groupType][attribute] = 0
          } else {
            data[vehicleType][groupType][attribute] = parseFloat(data[vehicleType][groupType][attribute])
          }
        }
      }
    }


    const units = (
      <tbody>
        <tr>
          <th colSpan="6">Units &amp; Contracts</th>
        </tr>
        <tr>
          <td>Units Delivered</td>
          <td className="data-col">{data.new.counts.total.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.total.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.total.toLocaleString()}</td>
          <td className="data-col">-</td>
          <td className="data-col">{(data.new.counts.total + data.used.counts.total + data.lease.counts.total).toLocaleString()}</td>
        </tr>
        <tr>
          <td>Finance Contracts</td>
          <td className="data-col">{data.new.counts.financed_deals.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.financed_deals.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.financed_deals.toLocaleString()}</td>
          <td className="data-col">-</td>
          <td className="data-col">{(data.new.counts.financed_deals + data.used.counts.financed_deals + data.lease.counts.financed_deals).toLocaleString()}</td>
        </tr>
        <tr>
          <td>Cash Deals</td>
          <td className="data-col">{data.new.counts.cash_deals.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.cash_deals.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.cash_deals.toLocaleString()}</td>
          <td className="data-col">-</td>
          <td className="data-col">{(data.new.counts.cash_deals + data.used.counts.cash_deals + data.lease.counts.cash_deals).toLocaleString()}</td>
        </tr>
        <tr>
          <td>Service Contracts</td>
          <td className="data-col">{data.new.counts.service_contracts.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.service_contracts.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.service_contracts.toLocaleString()}</td>
          <td className="data-col">{data.products.counts.service_contracts.toLocaleString()}</td>
          <td className="data-col">{data.totals.counts.service_contracts.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Gap Contracts</td>
          <td className="data-col">{data.new.counts.gap_contracts.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.gap_contracts.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.gap_contracts.toLocaleString()}</td>
          <td className="data-col">{data.products.counts.gap_contracts.toLocaleString()}</td>
          <td className="data-col">{data.totals.counts.gap_contracts.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Appearance Protection</td>
          <td className="data-col">{data.new.counts.appearance_protection_contracts.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.appearance_protection_contracts.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.appearance_protection_contracts.toLocaleString()}</td>
          <td className="data-col">{data.products.counts.appearance_protection_contracts.toLocaleString()}</td>
          <td className="data-col">{data.totals.counts.appearance_protection_contracts.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Tire &amp; Wheel</td>
          <td className="data-col">{data.new.counts.tire_wheel_contracts.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.tire_wheel_contracts.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.tire_wheel_contracts.toLocaleString()}</td>
          <td className="data-col">{data.products.counts.tire_wheel_contracts.toLocaleString()}</td>
          <td className="data-col">{data.totals.counts.tire_wheel_contracts.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Key Replacement</td>
          <td className="data-col">{data.new.counts.key_replacement_contracts.toLocaleString()}</td>
          <td className="data-col">{data.used.counts.key_replacement_contracts.toLocaleString()}</td>
          <td className="data-col">{data.lease.counts.key_replacement_contracts.toLocaleString()}</td>
          <td className="data-col">{data.products.counts.key_replacement_contracts.toLocaleString()}</td>
          <td className="data-col">{data.totals.counts.key_replacement_contracts.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Custom Products</td>
          <td className="data-col">{(data.new.counts.custom_product_1_contracts + data.new.counts.custom_product_2_contracts).toLocaleString()}</td>
          <td className="data-col">{(data.used.counts.custom_product_1_contracts + data.used.counts.custom_product_2_contracts).toLocaleString()}</td>
          <td className="data-col">{(data.lease.counts.custom_product_1_contracts + data.lease.counts.custom_product_2_contracts).toLocaleString()}</td>
          <td className="data-col">{(data.products.counts.custom_product_1_contracts + data.products.counts.custom_product_2_contracts).toLocaleString()}</td>
          <td className="data-col">{(data.totals.counts.custom_product_1_contracts + data.totals.counts.custom_product_2_contracts).toLocaleString()}</td>
        </tr>
      </tbody>
    )

    const penetration = (
      <tbody>
        <tr>
          <th colSpan="6">Penetration</th>
        </tr>
        <tr>
          <td>Finance</td>
          <td className="data-col">{this.doPercentage(data.new.counts.financed_deals, data.new.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.used.counts.financed_deals, data.used.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.lease.counts.financed_deals, data.lease.counts.total)}</td>
          <td className="data-col">-</td>
          <td className="data-col">{this.doPercentage((data.new.counts.financed_deals + data.used.counts.financed_deals + data.lease.counts.financed_deals), (data.new.counts.total + data.used.counts.total + data.lease.counts.total))}</td>
        </tr>
        <tr>
          <td>Service Agreements</td>
          <td className="data-col">{this.doPercentage(data.new.counts.service_contracts, data.new.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.used.counts.service_contracts, data.used.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.lease.counts.service_contracts, data.lease.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.products.counts.service_contracts, data.products.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.totals.counts.service_contracts, (data.new.counts.total + data.used.counts.total + data.lease.counts.total))}</td>
        </tr>
        <tr>
          <td>GAP Contracts</td>
          <td className="data-col">{this.doPercentage(data.new.counts.gap_contracts, data.new.counts.financed_deals)}</td>
          <td className="data-col">{this.doPercentage(data.used.counts.gap_contracts, data.used.counts.financed_deals)}</td>
          <td className="data-col">{this.doPercentage(data.lease.counts.gap_contracts, data.lease.counts.financed_deals)}</td>
          <td className="data-col">{this.doPercentage(data.products.counts.gap_contracts, data.products.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.totals.counts.gap_contracts, (data.new.counts.financed_deals + data.used.counts.financed_deals + data.lease.counts.financed_deals))}</td>
        </tr>
        <tr>
          <td>Tire &amp; Wheel</td>
          <td className="data-col">{this.doPercentage(data.new.counts.tire_wheel_contracts, data.new.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.used.counts.tire_wheel_contracts, data.used.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.lease.counts.tire_wheel_contracts, data.lease.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.products.counts.tire_wheel_contracts, data.products.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.totals.counts.tire_wheel_contracts, (data.new.counts.total + data.used.counts.total + data.lease.counts.total))}</td>
        </tr>
        <tr>
          <td>Appearance Protection</td>
          <td className="data-col">{this.doPercentage(data.new.counts.appearance_protection_contracts, data.new.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.used.counts.appearance_protection_contracts, data.used.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.lease.counts.appearance_protection_contracts, data.lease.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.products.counts.appearance_protection_contracts, data.products.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.totals.counts.appearance_protection_contracts, (data.new.counts.total + data.used.counts.total + data.lease.counts.total))}</td>
        </tr>
        <tr>
          <td>Key Replacement</td>
          <td className="data-col">{this.doPercentage(data.new.counts.key_replacement_contracts, data.new.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.used.counts.key_replacement_contracts, data.used.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.lease.counts.key_replacement_contracts, data.lease.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.products.counts.key_replacement_contracts, data.products.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.totals.counts.key_replacement_contracts, (data.new.counts.total + data.used.counts.total + data.lease.counts.total))}</td>
        </tr>
        <tr>
          <td>Custom Products</td>
          <td className="data-col">{this.doPercentage(data.new.counts.custom_product_1_contracts + data.new.counts.custom_product_2_contracts, data.new.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.used.counts.custom_product_1_contracts + data.used.counts.custom_product_2_contracts, data.used.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.lease.counts.custom_product_1_contracts + data.lease.counts.custom_product_2_contracts, data.lease.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.products.counts.custom_product_1_contracts + data.products.counts.custom_product_2_contracts, data.products.counts.total)}</td>
          <td className="data-col">{this.doPercentage(data.totals.counts.custom_product_1_contracts + data.totals.counts.custom_product_2_contracts, (data.new.counts.total + data.used.counts.total + data.lease.counts.total))}</td>
        </tr>
      </tbody>
    )

    const incomes = (
      <tbody>
        <tr>
          <th colSpan="6">Income</th>
        </tr>
        <tr>
          <td>Finance Reserve</td>
          <td className="data-col">{data.new.values.financial_reserve.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.financial_reserve.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.financial_reserve.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.financial_reserve.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.financial_reserve.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Service Contracts</td>
          <td className="data-col">{data.new.values.service_contracts.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.service_contracts.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.service_contracts.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.service_contracts.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.service_contracts.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>GAP Contracts</td>
          <td className="data-col">{data.new.values.gap.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.gap.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.gap.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.gap.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.gap.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Tire & Wheel</td>
          <td className="data-col">{data.new.values.tire_wheel.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.tire_wheel.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.tire_wheel.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.tire_wheel.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.tire_wheel.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Appearance Protection</td>
          <td className="data-col">{data.new.values.appearance_protection.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.appearance_protection.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.appearance_protection.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.appearance_protection.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.appearance_protection.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Key Replacement</td>
          <td className="data-col">{data.new.values.key_replacement.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.key_replacement.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.key_replacement.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.key_replacement.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.key_replacement.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Custom Products</td>
          <td className="data-col">{(data.new.values.custom_product_1_price + data.new.values.custom_product_2_price).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{(data.used.values.custom_product_1_price + data.used.values.custom_product_2_price).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{(data.lease.values.custom_product_1_price + data.lease.values.custom_product_2_price).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{(data.products.values.custom_product_1_price + data.products.values.custom_product_2_price).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{(data.totals.values.custom_product_1_price + data.totals.values.custom_product_2_price).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Total Backend Gross</td>
          <td className="data-col">{data.new.values.total_backend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.total_backend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.total_backend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.total_backend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.total_backend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Total Front Gross</td>
          <td className="data-col">{data.new.values.total_frontend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.total_frontend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.total_frontend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.total_frontend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.total_frontend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
        <tr>
          <td>Total Gross</td>
          <td className="data-col">{data.new.values.total_gross.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.used.values.total_gross.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.lease.values.total_gross.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.products.values.total_gross.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
          <td className="data-col">{data.totals.values.total_gross.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})}</td>
        </tr>
      </tbody>
    )

    let reservePerFinContractNew = '-'
    if (0 < data.new.counts.financed_deals) {
      reservePerFinContractNew = (data.new.values.financial_reserve/data.new.counts.financed_deals).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let reservePerFinContractUsed = '-'
    if (0 < data.used.counts.financed_deals) {
      reservePerFinContractUsed = (data.used.values.financial_reserve/data.used.counts.financed_deals).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let reservePerFinContractLease = '-'
    if (0 < data.lease.counts.financed_deals) {
      reservePerFinContractLease = (data.lease.values.financial_reserve/data.lease.counts.financed_deals).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let reservePerFinContractTotal = '-'
    if (0 < data.new.counts.financed_deals || 0 < data.used.counts.financed_deals || 0 < data.lease.counts.financed_deals) {
      reservePerFinContractTotal = ((data.new.values.financial_reserve + data.used.values.financial_reserve + data.lease.values.financial_reserve)/(data.new.counts.financed_deals + data.used.counts.financed_deals + data.lease.counts.financed_deals)).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }

    let serviceContractNew = '-'
    if (0 < data.new.counts.service_contracts) {
      serviceContractNew = (data.new.values.service_contracts/data.new.counts.service_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let serviceContractUsed = '-'
    if (0 < data.used.counts.service_contracts) {
      serviceContractUsed = (data.used.values.service_contracts/data.used.counts.service_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let serviceContractLease = '-'
    if (0 < data.lease.counts.service_contracts) {
      serviceContractLease = (data.lease.values.service_contracts/data.lease.counts.service_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let serviceContractProduct = '-'
    if (0 < data.products.counts.service_contracts) {
      serviceContractProduct = (data.products.values.service_contracts/data.products.counts.service_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let serviceContractTotal = '-'
    if (0 < data.new.counts.service_contracts || 0 < data.used.counts.service_contracts || 0 < data.lease.counts.service_contracts || 0 < data.products.counts.service_contracts) {
      serviceContractTotal = (data.totals.values.service_contracts/data.totals.counts.service_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }

    let gapContractNew = '-'
    if (0 < data.new.counts.gap_contracts) {
      gapContractNew = (data.new.values.gap/data.new.counts.gap_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let gapContractUsed = '-'
    if (0 < data.used.counts.gap_contracts) {
      gapContractUsed = (data.used.values.gap/data.used.counts.gap_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let gapContractLease = '-'
    if (0 < data.lease.counts.gap_contracts) {
      gapContractLease = (data.lease.values.gap/data.lease.counts.gap_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let gapContractProduct = '-'
    if (0 < data.products.counts.gap_contracts) {
      gapContractProduct = (data.products.values.gap/data.products.counts.gap_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let gapContractTotal = '-'
    if (0 < data.new.counts.gap_contracts || 0 < data.used.counts.gap_contracts || 0 < data.lease.counts.gap_contracts || 0 < data.products.counts.gap_contracts) {
      gapContractTotal = (data.totals.values.gap/data.totals.counts.gap_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }

    let tireWheelContractNew = '-'
    if (0 < data.new.counts.tire_wheel_contracts) {
      tireWheelContractNew = (data.new.values.tire_wheel/data.new.counts.tire_wheel_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let tireWheelContractUsed = '-'
    if (0 < data.used.counts.tire_wheel_contracts) {
      tireWheelContractUsed = (data.used.values.tire_wheel/data.used.counts.tire_wheel_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let tireWheelContractLease = '-'
    if (0 < data.lease.counts.tire_wheel_contracts) {
      tireWheelContractLease = (data.lease.values.tire_wheel/data.lease.counts.tire_wheel_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let tireWheelContractProduct = '-'
    if (0 < data.products.counts.tire_wheel_contracts) {
      tireWheelContractProduct = (data.products.values.tire_wheel/data.products.counts.tire_wheel_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let tireWheelContractTotal = '-'
    if (0 < data.new.counts.tire_wheel_contracts || 0 < data.used.counts.tire_wheel_contracts || 0 < data.lease.counts.tire_wheel_contracts || 0 < data.products.counts.tire_wheel_contracts) {
      tireWheelContractTotal = (data.totals.values.tire_wheel/data.totals.counts.tire_wheel_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }

    let appearanceProtectionContractNew = '-'
    if (0 < data.new.counts.appearance_protection_contracts) {
      appearanceProtectionContractNew = (data.new.values.appearance_protection/data.new.counts.appearance_protection_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let appearanceProtectionContractUsed = '-'
    if (0 < data.used.counts.appearance_protection_contracts) {
      appearanceProtectionContractUsed = (data.used.values.appearance_protection/data.used.counts.appearance_protection_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let appearanceProtectionContractLease = '-'
    if (0 < data.lease.counts.appearance_protection_contracts) {
      appearanceProtectionContractLease = (data.lease.values.appearance_protection/data.lease.counts.appearance_protection_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let appearanceProtectionContractProduct = '-'
    if (0 < data.products.counts.appearance_protection_contracts) {
      appearanceProtectionContractProduct = (data.products.values.appearance_protection/data.products.counts.appearance_protection_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let appearanceProtectionContractTotal = '-'
    if (0 < data.new.counts.appearance_protection_contracts || 0 < data.used.counts.appearance_protection_contracts || 0 < data.lease.counts.appearance_protection_contracts || 0 < data.products.counts.appearance_protection_contracts) {
      appearanceProtectionContractTotal = (data.totals.values.appearance_protection/data.totals.counts.appearance_protection_contracts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }

    let frontGrossContractNew = '-'
    if (0 < data.new.counts.total) {
      frontGrossContractNew = (data.new.values.total_frontend/data.new.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let frontGrossContractUsed = '-'
    if (0 < data.used.counts.total) {
      frontGrossContractUsed = (data.used.values.total_frontend/data.used.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let frontGrossContractLease = '-'
    if (0 < data.lease.counts.total) {
      frontGrossContractLease = (data.lease.values.total_frontend/data.lease.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let frontGrossContractTotal = '-'
    if (0 < data.new.counts.total || 0 < data.used.counts.total || 0 < data.lease.counts.total || 0 < data.products.counts.total) {
      frontGrossContractTotal = ((data.new.values.total_frontend + data.used.values.total_frontend + data.lease.values.total_frontend)/(data.new.counts.total + data.used.counts.total + data.lease.counts.total)).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }

    const averages = (
      <tbody>
        <tr>
          <th colSpan="6">Department Averages</th>
        </tr>
        <tr>
          <td>% F&amp;I Income from Reserve</td>
          <td className="data-col">{this.doPercentage(data.new.values.financial_reserve, data.new.values.total_backend)}</td>
          <td className="data-col">{this.doPercentage(data.used.values.financial_reserve, data.used.values.total_backend)}</td>
          <td className="data-col">{this.doPercentage(data.lease.values.financial_reserve, data.lease.values.total_backend)}</td>
          <td className="data-col">-</td>
          <td className="data-col">{this.doPercentage((data.new.values.financial_reserve + data.used.values.financial_reserve + data.lease.values.financial_reserve), (data.new.values.total_backend + data.used.values.total_backend + data.lease.values.total_backend))}</td>
        </tr>
        <tr>
          <td>Reserve <small>(per fin. contract)</small></td>
          <td className="data-col">{reservePerFinContractNew}</td>
          <td className="data-col">{reservePerFinContractUsed}</td>
          <td className="data-col">{reservePerFinContractLease}</td>
          <td className="data-col">-</td>
          <td className="data-col">{reservePerFinContractTotal}</td>
        </tr>
        <tr>
          <td>Service Contracts <small>(per contract)</small></td>
          <td className="data-col">{serviceContractNew}</td>
          <td className="data-col">{serviceContractUsed}</td>
          <td className="data-col">{serviceContractLease}</td>
          <td className="data-col">{serviceContractProduct}</td>
          <td className="data-col">{serviceContractTotal}</td>
        </tr>
        <tr>
          <td>Gap Contracts <small>(per contract)</small></td>
          <td className="data-col">{gapContractNew}</td>
          <td className="data-col">{gapContractUsed}</td>
          <td className="data-col">{gapContractLease}</td>
          <td className="data-col">{gapContractProduct}</td>
          <td className="data-col">{gapContractTotal}</td>
        </tr>
        <tr>
          <td>Tire &amp; Wheel <small>(per contract)</small></td>
          <td className="data-col">{tireWheelContractNew}</td>
          <td className="data-col">{tireWheelContractUsed}</td>
          <td className="data-col">{tireWheelContractLease}</td>
          <td className="data-col">{tireWheelContractProduct}</td>
          <td className="data-col">{tireWheelContractTotal}</td>
        </tr>
        <tr>
          <td>Appearance Protection <small>(per contract)</small></td>
          <td className="data-col">{appearanceProtectionContractNew}</td>
          <td className="data-col">{appearanceProtectionContractUsed}</td>
          <td className="data-col">{appearanceProtectionContractLease}</td>
          <td className="data-col">{appearanceProtectionContractProduct}</td>
          <td className="data-col">{appearanceProtectionContractTotal}</td>
        </tr>
        <tr>
          <td>Front Gross <small>(per vehicle)</small></td>
          <td className="data-col">{frontGrossContractNew}</td>
          <td className="data-col">{frontGrossContractUsed}</td>
          <td className="data-col">{frontGrossContractLease}</td>
          <td className="data-col">-</td>
          <td className="data-col">{frontGrossContractTotal}</td>
        </tr>
      </tbody>
    )

    let sumNew = 0,
        sumUsed = 0,
        sumLease = 0
    let averageProductsSoldNew = '-'
    if (0 < data.new.counts.total) {
      sumNew = data.new.counts.service_contracts + data.new.counts.gap_contracts + data.new.counts.appearance_protection_contracts + data.new.counts.tire_wheel_contracts + data.new.counts.key_replacement_contracts + data.new.counts.custom_product_1_contracts + data.new.counts.custom_product_2_contracts
      averageProductsSoldNew = (sumNew/data.new.counts.total).toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1})
    }
    let averageProductsSoldUsed = '-'
    if (0 < data.used.counts.total) {
      sumUsed = data.used.counts.service_contracts + data.used.counts.gap_contracts + data.used.counts.appearance_protection_contracts + data.used.counts.tire_wheel_contracts + data.used.counts.key_replacement_contracts + data.used.counts.custom_product_1_contracts + data.used.counts.custom_product_2_contracts
      averageProductsSoldUsed = (sumUsed/data.used.counts.total).toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1})
    }
    let averageProductsSoldLease = '-'
    if (0 < data.lease.counts.total) {
      sumLease = data.lease.counts.service_contracts + data.lease.counts.gap_contracts + data.lease.counts.appearance_protection_contracts + data.lease.counts.tire_wheel_contracts + data.lease.counts.key_replacement_contracts + data.lease.counts.custom_product_1_contracts + data.lease.counts.custom_product_2_contracts
      averageProductsSoldLease = (sumLease/data.lease.counts.total).toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1})
    }
    let averageProductsSoldTotal = '-'
    if (0 < data.new.counts.total || 0 < data.used.counts.total || 0 < data.lease.counts.total) {
      const sum = sumNew + sumUsed + sumLease
      const total = data.new.counts.total + data.used.counts.total + data.lease.counts.total
      averageProductsSoldTotal = (sum/total).toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1})
    }

    let averagePRUBackendNew = '-'
    let averagePRUFrontendNew = '-'
    let averagePRUGrossNew = '-'
    if (0 < data.new.counts.total) {
      averagePRUBackendNew = (data.new.values.total_backend/data.new.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUFrontendNew = (data.new.values.total_frontend/data.new.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUGrossNew = (data.new.values.total_gross/data.new.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let averagePRUBackendUsed = '-'
    let averagePRUFrontendUsed = '-'
    let averagePRUGrossUsed = '-'
    if (0 < data.used.counts.total) {
      averagePRUBackendUsed = (data.used.values.total_backend/data.used.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUFrontendUsed = (data.used.values.total_frontend/data.used.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUGrossUsed = (data.used.values.total_gross/data.used.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let averagePRUBackendLease = '-'
    let averagePRUFrontendLease = '-'
    let averagePRUGrossLease = '-'
    if (0 < data.lease.counts.total) {
      averagePRUBackendLease = (data.lease.values.total_backend/data.lease.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUFrontendLease = (data.lease.values.total_frontend/data.lease.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUGrossLease = (data.lease.values.total_gross/data.lease.counts.total).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }
    let averagePRUBackendTotal = '-'
    let averagePRUFrontendTotal = '-'
    let averagePRUGrossTotal = '-'
    if (0 < data.new.counts.total || 0 < data.used.counts.total || 0 < data.lease.counts.total) {
      const counts = data.new.counts.total + data.used.counts.total + data.lease.counts.total
      averagePRUBackendTotal = (data.totals.values.total_backend/counts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUFrontendTotal = (data.totals.values.total_frontend/counts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      averagePRUGrossTotal = ((data.new.values.total_backend + data.used.values.total_backend + data.lease.values.total_backend + data.products.values.total_backend + data.new.values.total_frontend + data.used.values.total_frontend + data.lease.values.total_frontend + data.products.values.total_frontend)/counts).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    }


    const summary = (
      <tbody>
        <tr>
          <th colSpan="6">Dealership Summary</th>
        </tr>
        <tr>
          <td colSpan="2">Avg Products Sold <small>(per vehicle)</small></td>
          <td className="data-col">{averageProductsSoldNew}</td>
          <td className="data-col">{averageProductsSoldUsed}</td>
          <td className="data-col">{averageProductsSoldLease}</td>
          <td className="data-col">{averageProductsSoldTotal}</td>
        </tr>
        <tr>
          <td colSpan="2">Backend PRU <small>(average)</small></td>
          <td className="data-col">{averagePRUBackendNew}</td>
          <td className="data-col">{averagePRUBackendUsed}</td>
          <td className="data-col">{averagePRUBackendLease}</td>
          <td className="data-col">{averagePRUBackendTotal}</td>
        </tr>
        <tr>
          <td colSpan="2">Frontend PRU <small>(average)</small></td>
          <td className="data-col">{averagePRUFrontendNew}</td>
          <td className="data-col">{averagePRUFrontendUsed}</td>
          <td className="data-col">{averagePRUFrontendLease}</td>
          <td className="data-col">{averagePRUFrontendTotal}</td>
        </tr>
        <tr>
          <td colSpan="2">Total Gross PRU <small>(average)</small></td>
          <td className="data-col">{averagePRUGrossNew}</td>
          <td className="data-col">{averagePRUGrossUsed}</td>
          <td className="data-col">{averagePRUGrossLease}</td>
          <td className="data-col">{averagePRUGrossTotal}</td>
        </tr>
      </tbody>
    )

    return (
      <table className="report-detailed-table">
        <thead>
          <tr>
            <th />
            <th className="data-col">New</th>
            <th className="data-col">Used</th>
            <th className="data-col">Lease</th>
            <th className="data-col">Product</th>
            <th className="data-col">Total</th>
          </tr>
        </thead>
        {units}
        <thead>
          <tr>
            <th />
            <th className="data-col">New</th>
            <th className="data-col">Used</th>
            <th className="data-col">Lease</th>
            <th className="data-col">Product</th>
            <th className="data-col">Total</th>
          </tr>
        </thead>
        {penetration}
        <thead>
          <tr>
            <th />
            <th className="data-col">New</th>
            <th className="data-col">Used</th>
            <th className="data-col">Lease</th>
            <th className="data-col">Product</th>
            <th className="data-col">Total</th>
          </tr>
        </thead>
        {incomes}
        <thead>
          <tr>
            <th />
            <th className="data-col">New</th>
            <th className="data-col">Used</th>
            <th className="data-col">Lease</th>
            <th className="data-col">Product</th>
            <th className="data-col">Average</th>
          </tr>
        </thead>
        {averages}
        <thead>
          <tr>
            <th colSpan="2" />
            <th className="data-col">New</th>
            <th className="data-col">Used</th>
            <th className="data-col">Lease</th>
            <th className="data-col">Average</th>
          </tr>
        </thead>
        {summary}
      </table>
    )
  }

}
