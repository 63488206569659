import React, { PureComponent } from 'react'

export default class Loader extends PureComponent {

  render() {
    return (
      <div id="app-loading">
        <h1>Hang Tight</h1>
        <p>Your data is loading</p>
        <div id="loading-icon">
          <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
            <circle cx="50" cy="50" fill="none" stroke="#27a570" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(30 50 50)">
              <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
            </circle>
          </svg>
        </div>
      </div>
    )
  }

  // render() {
  //   return (
  //     <div className="loading-icon">
  //       <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
  //         <circle cx="50" cy="50" fill="none" stroke="#27a570" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(30 50 50)">
  //           <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
  //         </circle>
  //       </svg>
  //     </div>
  //   )
  // }

}
