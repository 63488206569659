import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import reducers from './js/reducers'
import Auth from './js/containers/auth'
import './style.css'

const logger = (store) => (next) => (action) => {
  if ('production' !== process.env.NODE_ENV) {
    console.log('action fired', action)
  }
  next(action)
}

const store = createStore(reducers, applyMiddleware(logger, thunk))

if ('production' !== process.env.NODE_ENV) {
  store.subscribe( () => {
    console.log('Store changed', store.getState())
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route path="/" component={Auth} />
    </Router>
  </Provider>, document.getElementById('root'))

