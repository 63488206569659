import React, { PureComponent } from 'react'

export default class HTMLInput extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      autocomplete: this.props.autocomplete || '',
      errorMessage: '',
      format: this.props.format || 'any',
      hasError: this.props.hasError || false,
      label: this.props.label || '',
      map: this.props.map || '',
      readonly: this.props.readonly || false,
      required: this.props.required || false,
      type: this.props.type || 'text',
      value: this.props.value || ''
    }
    this._handleBlur = this._handleBlur.bind(this)
    this._handleFocus = this._handleFocus.bind(this)
    this._handleText = this._handleText.bind(this)

    if (undefined === this.props.onChange) {
      console.error('You must have an \'onChange\' prop on the HTMLInput component.')
    }
    if (undefined === this.props.value) {
      console.error('You must have a \'value\' prop on the HTMLInput component.')
    }
  }

  _handleBlur(e) {
    let value = e.target.value.trim()
    if ('' === value && true === this.state.required) {
      this.setState({
        hasError: true,
        errorMessage: 'Required'
      })
    } else if ('' !== value && 'currency' === this.state.format) {
      const regex = /[^0-9.]+/g
      let clean = parseFloat(value.replace(regex, '') * 1)
      if (true === this._isNumeric(clean)) {
        value = clean.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
      }
    } else if ('' !== value && 'email' === this.state.format) {
      if (false === this._isEmail(value)) {
        this.setState({
          hasError: true,
          errorMessage: this.props.errorMessage || 'Invalid email address'
        })
      }
    }
  }

  _handleFocus(e) {
    let newState = {}
    let value = e.target.value.trim()
    if ('currency' === this.state.format && '' !== value) {
      const regex = /[^0-9.]+/g
      let clean = parseFloat(value.replace(regex, '') * 1)
      if ('' !== clean && true === this._isNumeric(clean)) {
        newState['value'] = clean
        this.setState({
          value: clean
        })
      }
    }
  }

  _handleText(e) {
    let value = e.target.value.trimLeft()
    let regex
    if ('' !== value) {
      switch (this.state.format) {
        case 'alphanumeric':
          regex = /[^a-z0-9 ]+/gi
          if (true === regex.test(value)) {
            value = this.state.value
          }
          break
        case 'numeric':
          if (false === this._isNumeric(value)) {
            value = this.state.value
          }
          break
        default:
      }
    }
    this.setState({
      hasError: false,
      value: value
    }, () => {
      this.props.onChange(value, this.props.map)
    })
  }

  _isEmail(val) {
    const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    return regex.test(val)
  }

  _isNumeric(val) {
    return !isNaN(parseFloat(val)) && isFinite(val)
  }

  _maybePassBlurEvent(val, mappedValue = null) {

  }

  render() {

    let containerClasses = ['form-group', 'form-group-html-input']
    if (true === this.state.hasError) {
      containerClasses.push('form-group-has-error')
    }

    return (
      <div className={containerClasses.join(' ')}>
        <label>{this.state.label}</label>
        <input
          type={this.state.type}
          autoComplete={this.state.autocomplete}
          readOnly={this.state.readonly}
          onBlur={this._handleBlur}
          onChange={this._handleText}
          onFocus={this._handleFocus}
          value={this.state.value}
        />
        {this.state.hasError &&
          (<span className="form-group-error-message">{this.state.errorMessage}</span>)
        }
      </div>
    )
  }

}