export default (state = null, action) => {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const defaults = {
    dealership: '',
    endDate: today,
    hasLoginError: false,
    isLoggingIn: false,
    logDealType: 'all',
    startDate: firstDayOfMonth,
    token: ''
  }
  if (null === state ) {
    state = defaults
  }

  switch (action.type) {
    case 'DID_GET_DEALERSHIPS':
      if (action.payload.length) {
        state = {...state, ...{dealership: action.payload[0].id}}
      }
      break
    case 'DID_CHANGE_DEALERSHIP':
    case 'DID_CHANGE_DATE':
    case 'DID_CHANGE_LOG_REPORT_TYPE':
    case 'IS_LOGGING_IN':
    case 'LOG_IN':
    case 'LOG_IN_WITH_ERROR':
      state = {...state, ...action.payload}
      break
    default:
      return state
  }
  return state
}