import React, { PureComponent } from 'react'

export default class ReportGeneral extends PureComponent {

  render() {
    let data = this.props.data
    const sectionsDictionary = ['New Vehicles', 'Used Vehicles', 'Dealership Total', 'Penetration', 'Income (vehicle deals)', 'Products/Vehicle']
    let iKey = 0
    const sections = sectionsDictionary.map((section) => {
      let rows = []
      const object = data[section]
      for (let k in object) {
        iKey++
        rows.push(
          <li key={iKey}><span className="title">{k}</span><span className="value">{object[k]}</span></li>
        )
      }
      return (
        <li key={section}>
          <div className="report-general-card">
            <h2>{section}</h2>
            <ul>{rows}</ul>
          </div>
        </li>
      )
    })
    return (<ul className="report-general-list">{sections}</ul>)
  }

}
