import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { doLogOut } from '../actions'

const logo = require('../../images/logo.png')

class Header extends Component {

  render() {
    return (
      <header id="site-header">
        <div className="inner">
          <Link to="/" className="brand"><img src={logo} width="66" height="55" alt="Go Profit Path" /></Link>
          <nav>
            <ol>
              {this.props.loading &&
                <li>
                  <div id="loading-icon">
                    <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
                      <circle cx="50" cy="50" fill="none" stroke="#27a570" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(30 50 50)">
                        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
                      </circle>
                    </svg>
                  </div>
                </li>
              }
              <li><Link to="/entry/vehicle">Vehicle Deal</Link></li>
              <li><Link to="/entry/product">Product-Only</Link></li>
              <li><Link to="/">Reports</Link></li>
              <li><Link to="/logs">View Log</Link></li>
              {1 < this.props.dealerships.length &&
                (<li><Link to="/dealerships">Dealerships</Link></li>)
              }
              <li><button type="button" className="nav-link-button" onClick={this.props.doLogOut}>Log Out</button></li>
            </ol>
          </nav>
        </div>
      </header>
    )
  }

}

function mapStateToProps(state) {
  return {
    dealerships: state.dealerships,
    loading: state.loading
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    doLogOut: doLogOut
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Header)
