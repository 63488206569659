import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

export default class Reports extends PureComponent {

  render() {
    return (
      <div id="reports">
        <section className="page-header">
          <h1>Reports</h1>
          <p>Select a report below to continue.</p>
        </section>
        <ol className="reports-cards-list">
          <li>
            <Link to="/reports/general">
              <h2>General Overview</h2>
              <p>Vehicle Deals</p>
            </Link>
          </li>
          <li>
            <Link to="/reports/detailed-fm">
              <h2>Detailed Overview:</h2>
              <p>Finance Managers</p>
            </Link>
          </li>
          <li>
            <Link to="/reports/detailed-sm">
              <h2>Detailed Overview:</h2>
              <p>Sales Managers</p>
            </Link>
          </li>
          <li>
            <Link to="/reports/detailed-sales">
              <h2>Detailed Overview:</h2>
              <p>Salespeople</p>
            </Link>
          </li>
          <li>
            <Link to="/reports/ranking-fm">
              <h2>Ranking Report:</h2>
              <p>Finance Managers</p>
            </Link>
          </li>
          <li>
            <Link to="/reports/ranking-sm">
              <h2>Ranking Report:</h2>
              <p>Sales Managers</p>
            </Link>
          </li>
          <li>
            <Link to="/reports/ranking-sales">
              <h2>Ranking Report:</h2>
              <p>Salespeople</p>
            </Link>
          </li>
        </ol>
      </div>
    )
  }

}
