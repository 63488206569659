import React, { Component } from 'react'
import format from 'date-fns/format'
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import Select from '../components/select'

const logo = require('../../images/logo.png')

function displayCurrency(value){
  return parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
}

export default class ReportRanking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPDF: false,
      sortProperty: `pru_backend`,
    }

    this.changeSort = this.changeSort.bind(this)
    this.displayReportTimeframe = this.displayReportTimeframe.bind(this)
    this.displayReportTitle = this.displayReportTitle.bind(this)
    this.mungRankings = this.mungRankings.bind(this)
    this.sortRankings = this.sortRankings.bind(this)
    this.toggleShowPDF = this.toggleShowPDF.bind(this)
  }

  changeSort(value){
    this.setState({sortProperty: value})
  }

  displayReportTimeframe(){
    const {startDate, endDate} = this.props.rankingsOpts
    return `${format(startDate, 'MM/DD/YYYY')} - ${format(endDate, 'MM/DD/YYYY')}`
  }

  displayReportTitle(){
    const {type, typeDictionary} = this.props
    const found = typeDictionary.find(element => element.type === type)
    return `Go Profit Path ${found ? found.title: ''}`
  }

  mungRankings(rankings,sortProperty){
    const rankingsParsedUnits = rankings.map(ranking => {
      ranking.units = parseInt(ranking.units, 10)
      return ranking
    })
    return this.sortRankings(sortProperty, rankingsParsedUnits)
  }

  sortRankings(property, rankings){
    rankings.sort( (a,b) => {
      if (a[property] > b[property]) {
        return -1
      }
      if (a[property] < b[property]) {
        return 1
      }
      return 0;
    })
    return rankings
  }

  toggleShowPDF(){
    this.setState( (state) => ({ showPDF: !state.showPDF}) )
  }

  render() {
    if (-1 === this.props.type.search('ranking')) {
      return null
    }
    if (0 === this.props.data.length) {
      return (<p>No results found. Please adjust your search criteria.</p>)
    }
    const rankings = this.mungRankings(this.props.data, this.state.sortProperty)

    const thead = (
        <thead>
          <tr>
            <th />
            <th className="data-col">Units</th>
            <th className="data-col">Backend</th>
            { `pru_backend` !== this.state.sortProperty && <th className="data-col">Frontend</th>}
            { `pru` !== this.state.sortProperty && <th className="data-col">Backend PRU</th> }
            { `pru_backend` !== this.state.sortProperty && <th className="data-col">Total PRU</th>}
          </tr>
        </thead>
      )
    const rows = rankings.map((ranking) => {
        return (
          <tr key={ranking.id}>
            <td>{ranking.name}</td>
            <td className="data-col">{ranking.units.toLocaleString()}</td>
            <td className="data-col">{displayCurrency(ranking.backend)}</td>
            { `pru_backend` !== this.state.sortProperty && <td className="data-col">{displayCurrency(ranking.frontend)}</td>}
            { `pru` !== this.state.sortProperty && <td className="data-col">{ranking.pru_backend ? displayCurrency(ranking.pru_backend) : `-`}</td> }
            { `pru_backend` !== this.state.sortProperty && <td className="data-col">{ranking.pru ? displayCurrency(ranking.pru) : `-`}</td>}
          </tr>
        )
      })

    const BORDER_COLOR = '#bfbfbf'
    const BORDER_STYLE = 'solid'
    const ZEBRA_ROW_COLOR = '#ececec'
    let COLN_COUNT = 1
    let COL1_WIDTH = 1
    switch (this.state.sortProperty) {
      case 'pru':
        COLN_COUNT = 4
        COL1_WIDTH = 50
        break;

      case 'pru_backend':
        COLN_COUNT = 3
        COL1_WIDTH = 60
        break;

      case 'units':
        COLN_COUNT = 5
        COL1_WIDTH = 30
        break;

      default:
        COLN_COUNT = 1
        COL1_WIDTH = 40
        break;
    }
    const COLN_WIDTH = (100 - COL1_WIDTH) / COLN_COUNT
    const pdfStyles = StyleSheet.create({
      body: {
        padding: 10
      },
      header: {
        paddingBottom: '5%',
      },
      logo: {
        height: 55,
        width: 66,
      },
      table: { 
        display: "table", 
        width: "auto", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
      }, 
      tableRowEven: { 
        backgroundColor: ZEBRA_ROW_COLOR,
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableRowOdd: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableCol1Header: { 
        width: COL1_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
      },     
      tableColHeader: { 
        width: COLN_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
      },   
      tableCol1: { 
        width: COL1_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      },   
      tableCol: { 
        width: COLN_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCellHeader: {
        margin: 5, 
        fontSize: 12,
        fontWeight: 500
      },  
      tableCell: { 
        margin: 5, 
        fontSize: 10 
      }
    });

    const printHeader = (
      <View style={pdfStyles.header}>
        <Image src={logo} cache={true} style={pdfStyles.logo} />
        <Text>{this.displayReportTitle()}</Text>
        <Text>{this.props.rankingsOpts.activeDealership}</Text>
        <Text>{this.displayReportTimeframe()}</Text>
      </View>
    )

    const printTableHead = (
      <View style={pdfStyles.tableRowOdd}> 
        <View style={pdfStyles.tableCol1Header}> 
          <Text style={pdfStyles.tableCellHeader}>Name</Text> 
        </View> 
        <View style={pdfStyles.tableColHeader}> 
          <Text style={pdfStyles.tableCellHeader}>Units</Text> 
        </View> 
        <View style={pdfStyles.tableColHeader}> 
          <Text style={pdfStyles.tableCellHeader}>Backend</Text> 
        </View>
        { `pru_backend` !== this.state.sortProperty && 
        <View style={pdfStyles.tableColHeader}> 
          <Text style={pdfStyles.tableCellHeader}>Frontend</Text> 
        </View> }
        { `pru` !== this.state.sortProperty &&
        <View style={pdfStyles.tableColHeader}> 
          <Text style={pdfStyles.tableCellHeader}>Backend PRU</Text> 
        </View> }
        { `pru_backend` !== this.state.sortProperty && 
        <View style={pdfStyles.tableColHeader}> 
          <Text style={pdfStyles.tableCellHeader}>Total PRU</Text> 
        </View> }
      </View>
    )

    const printTableRows = rankings.map((ranking, index) => {
      const isEven = Boolean( index % 2 );
      const tableRow = isEven ? `tableRowEven` : `tableRowOdd`
      return (
        <View key={ranking.id} style={pdfStyles[tableRow]}> 
          <View style={pdfStyles.tableCol1}> 
            <Text style={pdfStyles.tableCell}>{ranking.name}</Text> 
          </View> 
          <View style={pdfStyles.tableCol}> 
            <Text style={pdfStyles.tableCell}>{ranking.units.toLocaleString()}</Text> 
          </View> 
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>{displayCurrency(ranking.backend)}</Text> 
          </View>
          { `pru_backend` !== this.state.sortProperty &&
          <View style={pdfStyles.tableCol}> 
            <Text style={pdfStyles.tableCell}>{displayCurrency(ranking.frontend)}</Text> 
          </View> }
          { `pru` !== this.state.sortProperty &&
          <View style={pdfStyles.tableCol}> 
            <Text style={pdfStyles.tableCell}>{ranking.pru_backend ? displayCurrency(ranking.pru_backend) : `-`}</Text> 
          </View> }
          { `pru_backend` !== this.state.sortProperty && 
          <View style={pdfStyles.tableCol}> 
            <Text style={pdfStyles.tableCell}>{ranking.pru ? displayCurrency(ranking.pru) : `-`}</Text> 
          </View> }
        </View> 
      )
    })

    const ReportExport = () => (
      <Document>
        <Page style={pdfStyles.body}>
          {printHeader}
          <View style={pdfStyles.table}> 
            {printTableHead}
            {printTableRows}
          </View>
        </Page>
      </Document>
    )

    return (
      <div>
        {!this.state.showPDF && 
        <div>
          <p>
            <button type="button" className="button-primary" onClick={this.toggleShowPDF}>Create PDF</button>
          </p>
          <Select
            label={`Sort By: `}
            onChange={this.changeSort}
            value={this.state.sortProperty}
            options={[
              {
                units: `Units`
              },
              {
                pru_backend: `Backend PRU`
              },
              {
                pru: `Total PRU`
              },
            ]}
          />
          <table className="report-detailed-table report-ranking-table">
            {thead}
            <tbody>{rows}</tbody>
          </table> 
        </div> }
        {this.state.showPDF && 
          <div>
            <p>
              <PDFDownloadLink className="button button-primary" document={<ReportExport />} fileName={`export${new Date().valueOf()}-goprofitpath.pdf`}>
                {({ blob, url, loading, error }) => (loading ? 'Creating PDF...' : 'Download PDF')}
              </PDFDownloadLink>
            </p>
            <p>Preview:</p>
            <PDFViewer>
              <ReportExport />
            </PDFViewer>
            <p>
              <button type="button" onClick={this.toggleShowPDF}>Back to Report</button>
            </p>
          </div>}
      </div>
    )
  }
  
}