import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import 'react-day-picker/lib/style.css'
import Select from '../components/select'
import Loader from '../components/loader'

class EntryVehicle extends Component {

  constructor(props) {
    super(props)
    this.state = {
      can_submit_form: false,
      is_submitting_form: false,
      id: '',
      txn_date: new Date(),
      txn_type: 'vehicle',
      customer_first: '',
      customer_last: '',
      finance_manager: '_',
      sales_manager: '_',
      salesperson_1: '_',
      salesperson_2: '_',
      bdc: '_',
      vehicle_year: new Date().getFullYear(),
      vehicle_make: '',
      vehicle_model: '',
      vehicle_stock_number: '',
      vehicle_txn_type: 'New',
      tradein_year: new Date().getFullYear() - 3,
      tradein_make: '',
      tradein_model: '',
      tradein_payment_type: 'Finance',
      tradein_delivery: '',
      deal_number: '',
      deal_type: '_',
      deal_lender: '_',
      amount_financed: '',
      cash: '',
      term: '',
      financial_reserve: '',
      front_gross_less_pack: '',
      holdback: '',
      mfr_incentive: '',
      pack: '',
      gap: '',
      service_contracts: '',
      appearance_protection: '',
      tire_wheel: '',
      key_replacement: '',
      custom_product_1_price: '',
      custom_product_2_price: '',
      comments: '',
      total_frontend: '$0.00',
      total_backend: '$0.00',
      total_gross: '$0.00'
    }
    this.changeBDC = this.changeBDC.bind(this)
    this.changeDealLender = this.changeDealLender.bind(this)
    this.changeDealType = this.changeDealType.bind(this)
    this.changeFinanceManager = this.changeFinanceManager.bind(this)
    this.changeTradeInPaymentType = this.changeTradeInPaymentType.bind(this)
    this.changeTradeInYear = this.changeTradeInYear.bind(this)
    this.changeSalesManager = this.changeSalesManager.bind(this)
    this.changeSalesperson1 = this.changeSalesperson1.bind(this)
    this.changeSalesperson2 = this.changeSalesperson2.bind(this)
    this.changeVehicleTxnType = this.changeVehicleTxnType.bind(this)
    this.changeVehicleYear = this.changeVehicleYear.bind(this)
    this.handleNumericInput = this.handleNumericInput.bind(this)
    this.handleTextInput = this.handleTextInput.bind(this)
    this.handleTradeInDeliveryClick = this.handleTradeInDeliveryClick.bind(this)
    this.handleTxnDayClick = this.handleTxnDayClick.bind(this)
    this.maybeEnableSubmitForm = this.maybeEnableSubmitForm.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  changeBDC(val) {
    this.setState({
      bdc: val
    }, this.maybeEnableSubmitForm)
  }

  changeDealLender(val) {
    this.setState({
      deal_lender: val
    }, this.maybeEnableSubmitForm)
  }

  changeDealType(val) {
    this.setState({
      deal_type: val
    }, this.maybeEnableSubmitForm)
  }

  changeFinanceManager(val) {
    this.setState({
      finance_manager: val
    }, this.maybeEnableSubmitForm)
  }

  changeSalesManager(val) {
    this.setState({
      sales_manager: val
    }, this.maybeEnableSubmitForm)
  }

  changeTradeInPaymentType(val) {
    this.setState({
      tradein_payment_type: val
    }, this.maybeEnableSubmitForm)
  }

  changeTradeInYear(val) {
    this.setState({
      tradein_year: parseInt(val, 10)
    }, this.maybeEnableSubmitForm)
  }

  changeSalesperson1(val) {
    this.setState({
      salesperson_1: val
    }, this.maybeEnableSubmitForm)
  }

  changeSalesperson2(val) {
    this.setState({
      salesperson_2: val
    }, this.maybeEnableSubmitForm)
  }

  changeVehicleTxnType(val) {
    this.setState({
      vehicle_txn_type: val
    }, this.maybeEnableSubmitForm)
  }

  changeVehicleYear(val) {
    this.setState({
      vehicle_year: parseInt(val, 10)
    }, this.maybeEnableSubmitForm)
  }

  handleNumericInput(e) {
    const val = e.target.value.trimLeft()
    if (false === isNaN(val) || '-' === val) {
      this.setState({
        [e.target.name]: val
      }, this.maybeEnableSubmitForm)
    }
  }

  handleTextInput(e) {
    const val = e.target.value.trimLeft()
    this.setState({
      [e.target.name]: val
    }, this.maybeEnableSubmitForm)
  }

  handleTxnDayClick(selectedDay) {
    this.setState({
      txn_date: selectedDay
    }, this.maybeEnableSubmitForm)
  }

  handleTradeInDeliveryClick(selectedDay) {
    this.setState({
      tradein_delivery: selectedDay
    }, this.maybeEnableSubmitForm)
  }

  maybeEnableSubmitForm() {
    let total_frontend = 0
    if ('' !== this.state.front_gross_less_pack) {
      total_frontend += parseFloat(this.state.front_gross_less_pack)
    }
    if ('' !== this.state.holdback) {
      total_frontend += parseFloat(this.state.holdback)
    }
    if ('' !== this.state.mfr_incentive) {
      total_frontend += parseFloat(this.state.mfr_incentive)
    }
    if ('' !== this.state.pack) {
      total_frontend += parseFloat(this.state.pack)
    }
    let total_backend = 0
    if ('' !== this.state.financial_reserve) {
      total_backend += parseFloat(this.state.financial_reserve)
    }
    if ('' !== this.state.service_contracts) {
      total_backend += parseFloat(this.state.service_contracts)
    }
    if ('' !== this.state.gap) {
      total_backend += parseFloat(this.state.gap)
    }
    if ('' !== this.state.appearance_protection) {
      total_backend += parseFloat(this.state.appearance_protection)
    }
    if ('' !== this.state.tire_wheel) {
      total_backend += parseFloat(this.state.tire_wheel)
    }
    if ('' !== this.state.key_replacement) {
      total_backend += parseFloat(this.state.key_replacement)
    }

    const total_gross = total_frontend + total_backend
    const form_is_incomplete =  '' === this.state.txn_date
                                || '_' === this.state.finance_manager
                                || '_' === this.state.sales_manager
                                || '_' === this.state.salesperson_1
                                || '' === this.state.vehicle_make
                                || '' === this.state.vehicle_model
                                || '' === this.state.vehicle_stock_number

    this.setState({
      can_submit_form: !form_is_incomplete,
      total_frontend: total_frontend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2}),
      total_backend: total_backend.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2}),
      total_gross: total_gross.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})
    })
  }

  submitForm() {
    this.setState({
      can_submit_form: false,
      is_submitting_form: true
    }, () => {
      axios.post(`${process.env.REACT_APP_WP_REST_API}/deal`, {...{
        dealership: this.props.user.dealership,
        token: this.props.user.token
      }, ...this.state})
      .then((response) => {
        this.props.history.push('/')
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
    })
  }

  render() {
    let form
    let description
    if ('all' === this.props.dealerships.length) {
      description = (<p>You are logged into multiple dealerships. Please select a dealership to continue.</p>)
      form = (<p><Link to="/dealerships">Click here to select a dealership</Link>.</p>)
    } else {
      let changeDealership
      let dealership = {
        id: '',
        name: '',
        finance_managers: [],
        sales_managers: [],
        salespeople: [],
        bdc: [],
        lenders: [],
        deal_types: []
      }
      if (1 < this.props.dealerships.length) {
        changeDealership = (<span><Link to="/dealerships">Click here to change dealerships</Link>.</span>)
      }
      for (let i = 0; i < this.props.dealerships.length; i++) {
        if (this.props.user.dealership === this.props.dealerships[i].id) {
          dealership = this.props.dealerships[i]
        }
      }
      description = (<p>Use this form to add a vehicle deal at <strong>{dealership.name}</strong>. {changeDealership}</p>)
      const dateFormat = 'M/D/YYYY'
      let yearList = []
      for (let i = 0; i < 50; i++) {
        const year = new Date().getFullYear() + 3 - i
        // yearList[year] = year
        yearList.push(year)
      }
      let finance_managers = {'_' : 'Select One'}
      if (0 < dealership.finance_managers.length) {
        for (let i = 0; i < dealership.finance_managers.length; i++) {
          finance_managers[dealership.finance_managers[i].id] = dealership.finance_managers[i].name
        }
      }
      let sales_managers = {'_' : 'Select One'}
      if (0 < dealership.sales_managers.length) {
        for (let i = 0; i < dealership.sales_managers.length; i++) {
          sales_managers[dealership.sales_managers[i].id] = dealership.sales_managers[i].name
        }
      }
      let salesperson_1 = {'_' : 'Select One'}
      let salesperson_2 = {'_' : 'Select One'}
      if (0 < dealership.salespeople.length) {
        for (let i = 0; i < dealership.salespeople.length; i++) {
          salesperson_1[dealership.salespeople[i].id] = dealership.salespeople[i].name
          salesperson_2[dealership.salespeople[i].id] = dealership.salespeople[i].name
        }
      }
      let bdc = {'_' : 'Select One'}
      if (0 < dealership.bdc.length) {
        for (let i = 0; i < dealership.bdc.length; i++) {
          salesperson_1[dealership.bdc[i].id] = dealership.bdc[i].name
        }
      }
      let lenders = {'_' : 'Select One'}
      if (0 < dealership.lenders.length) {
        for (let i = 0; i < dealership.lenders.length; i++) {
          lenders[dealership.lenders[i].id] = dealership.lenders[i].name
        }
      }
      let deal_types = {'_' : 'Select One'}
      if (0 < dealership.deal_types.length) {
        for (let i = 0; i < dealership.deal_types.length; i++) {
          deal_types[dealership.deal_types[i].id] = dealership.deal_types[i].name
        }
      }

      if (true === this.state.is_submitting_form) {
        form = (<Loader />)
      } else {
        form = (
          <div className="form">
            <h2>Deal Information</h2>
            <div className="form-row">
              <div className="form-column form-column-33">
                <label>Deal/Transaction Date <span className="required">*</span></label>
                <DayPickerInput
                  formatDate={format}
                  format={dateFormat}
                  parseDate={parse}
                  placeholder="Transaction Date"
                  dayPickerProps={{
                    disabledDays: [{
                      after: new Date()
                    }],
                    month: new Date(),
                    selectedDays: this.state.txn_date,
                    onDayClick: this.handleTxnDayClick,
                    toMonth: new Date(),
                    showOutsideDays: true,
                    todayButton: 'Today',
                  }}
                  value={this.state.txn_date}
                />
              </div>
              <div className="form-column form-column-33">
                <label>Customer First Name</label>
                <input type="text" name="customer_first" onChange={this.handleTextInput} value={this.state.customer_first} />
              </div>
              <div className="form-column form-column-33">
                <label>Customer Last Name</label>
                <input type="text" name="customer_last" onChange={this.handleTextInput} value={this.state.customer_last} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-50">
                <label>Finance Manager <span className="required">*</span></label>
                <Select
                  onChange={this.changeFinanceManager}
                  options={finance_managers}
                  value={this.state.finance_manager}
                />
              </div>
              <div className="form-column form-column-50">
                <label>Sales Manager <span className="required">*</span></label>
                <Select
                  onChange={this.changeSalesManager}
                  options={sales_managers}
                  value={this.state.sales_manager}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-33">
                <label>Salesperson <span className="required">*</span></label>
                <Select
                  onChange={this.changeSalesperson1}
                  options={salesperson_1}
                  value={this.state.salesperson_1}
                />
              </div>
              <div className="form-column form-column-33">
                <label>Add&#8217;l Salesperson</label>
                <Select
                  onChange={this.changeSalesperson2}
                  options={salesperson_2}
                  value={this.state.salesperson_2}
                />
              </div>
              <div className="form-column form-column-33">
                <label>BDC</label>
                <Select
                  onChange={this.changeBDC}
                  options={bdc}
                  value={this.state.bdc}
                />
              </div>
            </div>
            <h2>Vehicle Information</h2>
            <div className="form-row">
              <div className="form-column form-column-20">
                <label>Year <span className="required">*</span></label>
                <Select
                  onChange={this.changeVehicleYear}
                  options={yearList}
                  value={this.state.vehicle_year}
                />
              </div>
              <div className="form-column form-column-20">
                <label>Make <span className="required">*</span></label>
                <input type="text" name="vehicle_make" onChange={this.handleTextInput} value={this.state.vehicle_make} />
              </div>
              <div className="form-column form-column-20">
                <label>Model <span className="required">*</span></label>
                <input type="text" name="vehicle_model" onChange={this.handleTextInput} value={this.state.vehicle_model} />
              </div>
              <div className="form-column form-column-20">
                <label>Stock # <span className="required">*</span></label>
                <input type="text" name="vehicle_stock_number" onChange={this.handleTextInput} value={this.state.vehicle_stock_number} />
              </div>
              <div className="form-column form-column-20">
                <label>Vehicle Type <span className="required">*</span></label>
                <Select
                  onChange={this.changeVehicleTxnType}
                  options={['New', 'Used', 'Lease']}
                  value={this.state.vehicle_txn_type}
                />
              </div>
            </div>
            <h2>Trade-In &amp; Delivery</h2>
            <div className="form-row">
              <div className="form-column form-column-20">
                <label>Year</label>
                <Select
                  onChange={this.changeTradeInYear}
                  options={yearList}
                  value={this.state.tradein_year}
                />
              </div>
              <div className="form-column form-column-20">
                <label>Make</label>
                <input type="text" name="tradein_make" onChange={this.handleTextInput} value={this.state.tradein_make} />
              </div>
              <div className="form-column form-column-20">
                <label>Model</label>
                <input type="text" name="tradein_model" onChange={this.handleTextInput} value={this.state.tradein_model} />
              </div>
              <div className="form-column form-column-20">
                <label>Payment Type</label>
                <Select
                  onChange={this.changeTradeInPaymentType}
                  options={['Finance', 'Cash']}
                  value={this.state.tradein_payment_type}
                />
              </div>
              <div className="form-column form-column-20">
                <label>Delivery Date</label>
                <DayPickerInput
                  formatDate={format}
                  format={dateFormat}
                  parseDate={parse}
                  placeholder="Start Date"
                  dayPickerProps={{
                    month: new Date(),
                    selectedDays: this.state.tradein_delivery,
                    onDayClick: this.handleTradeInDeliveryClick,
                    showOutsideDays: true,
                    todayButton: 'Today',
                  }}
                  value={this.state.tradein_delivery}
                />
              </div>
            </div>
            <h2>Deal Details</h2>
            <div className="form-row">
              <div className="form-column form-column-33">
                <label>Deal Number</label>
                <input type="text" name="deal_number" onChange={this.handleTextInput} value={this.state.deal_number} />
              </div>
              <div className="form-column form-column-33">
                <label>Deal Type</label>
                <Select
                  onChange={this.changeDealType}
                  options={deal_types}
                  value={this.state.deal_type}
                />
              </div>
              <div className="form-column form-column-33">
                <label>Deal Lender</label>
                <Select
                  onChange={this.changeDealLender}
                  options={lenders}
                  value={this.state.deal_lender}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>Amount Financed</label>
                <input type="text" name="amount_financed" onChange={this.handleNumericInput} value={this.state.amount_financed} />
              </div>
              <div className="form-column form-column-25">
                <label>Cash</label>
                <input type="text" name="cash" onChange={this.handleNumericInput} value={this.state.cash} />
              </div>
              <div className="form-column form-column-25">
                <label>Term</label>
                <input type="text" name="term" onChange={this.handleTextInput} value={this.state.term} />
              </div>
              <div className="form-column form-column-25">
                <label>Financial Reserve</label>
                <input type="text" name="financial_reserve" onChange={this.handleNumericInput} value={this.state.financial_reserve} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>Front Gross <small>(Less Pack)</small></label>
                <input type="text" name="front_gross_less_pack" onChange={this.handleNumericInput} value={this.state.front_gross_less_pack} />
              </div>
              <div className="form-column form-column-25">
                <label>Holdback</label>
                <input type="text" name="holdback" onChange={this.handleNumericInput} value={this.state.holdback} />
              </div>
              <div className="form-column form-column-25">
                <label>MFR. Incentives</label>
                <input type="text" name="mfr_incentive" onChange={this.handleTextInput} value={this.state.mfr_incentive} />
              </div>
              <div className="form-column form-column-25">
                <label>Pack</label>
                <input type="text" name="pack" onChange={this.handleNumericInput} value={this.state.pack} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>GAP</label>
                <input type="text" name="gap" onChange={this.handleNumericInput} value={this.state.gap} />
              </div>
              <div className="form-column form-column-25">
                <label>Service Contracts</label>
                <input type="text" name="service_contracts" onChange={this.handleNumericInput} value={this.state.service_contracts} />
              </div>
              <div className="form-column form-column-25">
                <label>Appearance Protection</label>
                <input type="text" name="appearance_protection" onChange={this.handleTextInput} value={this.state.appearance_protection} />
              </div>
              <div className="form-column form-column-25">
                <label>Tire &amp; Wheel</label>
                <input type="text" name="tire_wheel" onChange={this.handleNumericInput} value={this.state.tire_wheel} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column form-column-25">
                <label>Key Replacement</label>
                <input type="text" name="key_replacement" onChange={this.handleNumericInput} value={this.state.key_replacement} />
              </div>
              <div className="form-column form-column-25">
                <label>Custom Product</label>
                <input type="text" name="custom_product_1_price" onChange={this.handleNumericInput} value={this.state.custom_product_1_price} />
              </div>
              <div className="form-column form-column-25">
                <label>Custom Product</label>
                <input type="text" name="custom_product_2_price" onChange={this.handleTextInput} value={this.state.custom_product_2_price} />
              </div>
              <div className="form-column form-column-25">
                <label>Add&#8217;l Comments</label>
                <input type="text" name="comments" onChange={this.handleTextInput} value={this.state.comments} />
              </div>
            </div>
            <h2>Transaction Summary</h2>
            <div className="form-row">
              <div className="form-column form-column-33">
                <label>Front-End Total</label>
                <input type="text" value={this.state.total_frontend} readOnly />
              </div>
              <div className="form-column form-column-33">
                <label>Back-End Total</label>
                <input type="text" value={this.state.total_backend} readOnly />
              </div>
              <div className="form-column form-column-33">
                <label>Total Gross</label>
                <input type="text" value={this.state.total_gross} readOnly />
              </div>
            </div>
            <p><button type="button" className="button-primary" onClick={this.submitForm} disabled={!this.state.can_submit_form}>Submit</button></p>
          </div>
        )
      }
    }

    return (
      <div id="entry-form-container">
        <section className="page-header">
          <h1>Vehicle Deal Entry</h1>
          {description}
        </section>
        {form}
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    deal: state.deal,
    dealerships: state.dealerships,
    people: state.people,
    user: state.user
  }
}

export default withRouter(connect(mapStateToProps)(EntryVehicle))
