import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import 'react-day-picker/lib/style.css'
import { didChangeDate, didChangeLogReportType } from '../actions'
import Select from '../components/select'

const dateFormat = 'M/D/YYYY'
const availableDropdowns = [
  {'all' : 'All Vehicles'},
  {'new' : 'New Vehicles'},
  {'used' : 'Used Vehicles'},
  {'lease' : 'Leased Vehicles'}
]

class Logs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dealType: this.props.user.logDealType,
      endDay: this.props.user.endDate,
      endDayMin: this.props.user.startDate,
      selectedDeal: '',
      startDay: this.props.user.startDate
    }
    this.changeReportType = this.changeReportType.bind(this)
    this.handleEndDayClick = this.handleEndDayClick.bind(this)
    this.handleStartDayClick = this.handleStartDayClick.bind(this)
    this.showDetails = this.showDetails.bind(this)
  }

  componentDidMount() {
    this.props.didChangeLogReportType(this.props.user.logDealType)
  }

  changeReportType(type) {
    this.props.didChangeLogReportType(type)
  }

  handleEndDayClick(selectedDay) {
    this.props.didChangeDate({
      endDate: selectedDay
    })
  }

  handleStartDayClick(selectedDay) {
    this.props.didChangeDate({
      startDate: selectedDay
    })
  }

  showDetails(e) {
    let id = e.target.dataset.id
    if (id === this.state.selectedDeal) {
      id = ''
    }
    this.setState({
      selectedDeal: id
    })
  }

  render() {
    let activeDealership
    if ('all' === this.props.user.dealership) {
      activeDealership = 'all of your dealerships combined'
    } else {
      const dealership = this.props.dealerships.filter((dealership) => {
        return this.props.user.dealership === dealership.id
      })
      if (dealership.length) {
        activeDealership = dealership[0]['name']
      }
    }

    let rows
    if (0 === this.props.logs.length) {
      if (true === this.props.loading) {
        rows = (<p>Please be patient &ndash; the logs are being generated.</p>)
      } else {
        rows = (<p>No matching transactions found. Please update your search criteria and try again.</p>)
      }
    } else {
      let countText = 'One transaction found</small></p>'
      if (1 < this.props.logs.length) {
        countText = `${this.props.logs.length.toLocaleString()} transactions found`
      }

      const transactions = this.props.logs.map((transaction) => {
        let classNames = ''
        if (transaction.id === this.state.selectedDeal) {
          classNames = 'txn-details-open'
        }
        let txnLink = `${process.env.REACT_APP_WP}/admin.php?page=gpp-manage-deals&action=edit&id=${transaction.id}`
        let allTxnDetails = []
        for (let key in transaction) {
          if (
            key !== 'id' &&
            key !== 'created' &&
            key !== 'created_by' &&
            key !== 'customer_first' &&
            key !== 'customer_last' &&
            key !== 'dealership' &&
            key !== 'txn_date' &&
            key !== 'txn_date_formatted' &&
            null !== transaction[key]
          ) {
            let label = key.replace(/_/g, ' ')
            allTxnDetails.push(`${label.toUpperCase()}: ${transaction[key]}`)
          }
        }
        let iKey = 0
        const allTxnData = allTxnDetails.map((txn) => {
          iKey++
          return (<li key={iKey}>{txn}</li>)
        })

        return (<li key={transaction.id} id={transaction.id} className={classNames}>
          <div className="top" onClick={this.showDetails} data-id={transaction.id}>
            <h2>{transaction.customer_first.toUpperCase()} {transaction.customer_last.toUpperCase()}</h2>
            <span className="txn-date">{transaction.txn_date_formatted}</span>
          </div>
          <div className="details">
            <ul>{allTxnData}</ul>
            <p><a href={txnLink} target="_blank" rel="noopener noreferrer">Edit this deal</a></p>
          </div>
        </li>)
      })

      rows = (
        <div className="log-results">
          <p><small>{countText}</small></p>
          <ol>{transactions}</ol>
        </div>
      )
    }

    return (
      <div id="logs">
        <section className="page-header">
          <h1>Logs</h1>
          <p>You are currently viewing the logs for <strong>{activeDealership}</strong>.{1 < this.props.dealerships.length &&
            <span> <Link to="/dealerships">Click here to change dealerships</Link>.</span>
          }</p>
          <div className="logs-filters-row">
            <div className="logs-filters-column">
              <Select
                onChange={this.changeReportType}
                options={availableDropdowns}
                value={this.props.user.logDealType}
              />
            </div>
            <div className="logs-filters-column">
              <DayPickerInput
                formatDate={format}
                format={dateFormat}
                parseDate={parse}
                placeholder="Start Date"
                dayPickerProps={{
                  disabledDays: [{
                    after: this.props.user.endDate
                  }],
                  month: new Date(),
                  selectedDays: this.props.user.startDate,
                  onDayClick: this.handleStartDayClick,
                  toMonth: new Date(),
                  showOutsideDays: true,
                  todayButton: 'Today',
                }}
                value={this.props.user.startDate}
              />
            </div>
            <div className="logs-filters-column">
              <DayPickerInput
                formatDate={format}
                format={dateFormat}
                parseDate={parse}
                placeholder="End Date"
                dayPickerProps={{
                  disabledDays: [{
                    after: new Date(),
                    before: this.props.user.startDate,
                  }],
                  month: new Date(),
                  selectedDays: this.props.user.endDate,
                  onDayClick: this.handleEndDayClick,
                  toMonth: new Date(),
                  showOutsideDays: true,
                  todayButton: 'Today',
                }}
                value={this.props.user.endDate}
              />
            </div>
          </div>
        </section>
        {rows}
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    dealerships: state.dealerships,
    loading: state.loading,
    logs: state.logs,
    user: state.user
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    didChangeDate: didChangeDate,
    didChangeLogReportType: didChangeLogReportType
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Logs)
