import React, { Component } from 'react'

export default class Select extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this._handleClickOutside = this._handleClickOutside.bind(this)
    this._selectOption = this._selectOption.bind(this)
    this._toggleSelect = this._toggleSelect.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside)
  }

  _handleClickOutside(e) {
    if (false === this.node.contains(e.target)){
      this.setState({
        open: false
      })
    }
  }

  _selectOption(e) {
    const selected = e.target.name

    this.setState({
      open: false
    }, () => {
      this.props.onChange(selected)
    })
  }

  _toggleSelect() {
    this.setState((prevState) => ({
      open: !prevState.open
    }))
  }

  render() {
    let selectClassesList = ['pretty-select']
    if (true === this.state.open) {
      selectClassesList.push('open')
    }
    const selectClasses = selectClassesList.join(' ')

    let options
    let activeName

    if (true === Array.isArray(this.props.options)) {
      if (typeof this.props.options[0] !== 'object') {
        let iKey = 0
        options = this.props.options.map((option) => {
          iKey++
          if (this.props.value === option) {
            activeName = option
          }
          return (<li key={iKey}><button type="button" onClick={this._selectOption} name={option}>{option}</button></li>)
        })
      } else {
        options = this.props.options.map((option) => {
          for (let key in option) {
            if (this.props.value === key) {
              activeName = option[key]
            }
            return (<li key={key}><button type="button" onClick={this._selectOption} name={key}>{option[key]}</button></li>)
          }
          return null
        })
      }
    } else {
      options = []
      for (let key in this.props.options) {
        if (this.props.value === key) {
          activeName = this.props.options[key]
        }
        options.push(<li key={key}><button type="button" onClick={this._selectOption} name={key}>{this.props.options[key]}</button></li>)
      }
    }

    return (
      <div className={selectClasses} ref={node => this.node = node}>
        { this.props.label && <span className="label">{this.props.label}</span>}
        <p><button type="button" onClick={this._toggleSelect}>{activeName}</button></p>
        <ol>{options}</ol>
      </div>
    )
  }

}
