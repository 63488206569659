import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import 'react-day-picker/lib/style.css'
import { didChangeDate, getUpdatedReport } from '../actions'
import Select from '../components/select'
import ReportDetailed from '../components/report-detailed'
import ReportGeneral from '../components/report-general'
import ReportRanking from '../components/report-ranking'

const dateFormat = 'M/D/YYYY'
const reportDictionary = [{
  type: 'general',
  title: 'General Report'
},{
  type: 'detailed-fm',
  title: 'Detailed Report: Finance Managers'
},{
  type: 'detailed-sm',
  title: 'Detailed Report: Sales Managers'
},{
  type: 'detailed-sales',
  title: 'Detailed Report: Salespeople'
},{
  type: 'ranking-fm',
  title: 'Ranking Report: Finance Managers'
},{
  type: 'ranking-sm',
  title: 'Ranking Report: Sales Managers'
},{
  type: 'ranking-sales',
  title: 'Ranking Report: Salespeople'
}]

class Report extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      people: [],
      personSelected: 'all'
    }
    this.changePerson = this.changePerson.bind(this)
    this.handleEndDayClick = this.handleEndDayClick.bind(this)
    this.handleStartDayClick = this.handleStartDayClick.bind(this)
    this.sortAscendingByOrder = this.sortAscendingByOrder.bind(this)
  }

  componentDidMount() {
    if (this.props.reports.type !== this.props.match.params.type) {
      this.props.getUpdatedReport(this.props.match.params.type, this.state.personSelected)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dealerships.length && -1 !== nextProps.match.params.type.search('detailed')) {
      let personType
      if ('detailed-fm' === nextProps.match.params.type) {
        personType = 'finance_managers'
      }
      if ('detailed-sm' === nextProps.match.params.type) {
        personType = 'sales_managers'
      }
      if ('detailed-sales' === nextProps.match.params.type) {
        personType = 'salespeople'
      }

      let people = []
      if ('all' === nextProps.user.dealership) {
        for (let i = 0; i < nextProps.dealerships.length; i++) {
          people.push(...nextProps.dealerships[i][personType])
        }
      } else {
        const dealership = nextProps.dealerships.filter((dealership) => {
          return nextProps.user.dealership === dealership.id
        })
        if (dealership.length) {
          people.push(...dealership[0][personType])
        }
      }
      let newPeople = people.map((person) => {
        return {
          [person.id]: person.name
        }
      })
      newPeople.unshift({'all': 'All People'})
      return {
        people: newPeople
      }
    }
    return {}
  }

  changePerson(val) {
    this.setState({
      personSelected: val
    }, () => {
      this.props.getUpdatedReport(this.props.match.params.type, this.state.personSelected)
    })
  }

  handleEndDayClick(selectedDay) {
    this.props.didChangeDate({
      endDate: selectedDay
    })
    this.props.getUpdatedReport(this.props.match.params.type, this.state.personSelected)
  }

  handleStartDayClick(selectedDay) {
    this.props.didChangeDate({
      startDate: selectedDay
    })
    this.props.getUpdatedReport(this.props.match.params.type, this.state.personSelected)
  }

  sortAscendingByOrder(a, b) {
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 0
  }

  render() {
    const header = reportDictionary.filter((details) => {
      return details.type === this.props.match.params.type
    })[0]
    let activeDealership
    let theDealership
    if ('all' === this.props.user.dealership) {
      activeDealership = 'all of your dealerships combined'
    } else {
      const dealership = this.props.dealerships.filter((dealership) => {
        return this.props.user.dealership === dealership.id
      })
      if (dealership.length) {
        theDealership = dealership[0]
        activeDealership = theDealership.name
      }
    }

    let ifCanChangeDealership = (<span>.</span>)
    if (1 < this.props.dealerships.length) {
      ifCanChangeDealership = (<span>, or <Link to="/dealerships">click here to change dealerships</Link>.</span>)
    }

    let report = (<p>Loading report</p>)
    if (false === this.props.loading && _.get(this.props, 'reports.data')) {
      if (0 === Object.keys(this.props.reports.data).length) {
        report = (<p>No data to show</p>)
      } else {
        switch (this.props.match.params.type) {
          case 'general':
            report = (<ReportGeneral data={this.props.reports.data} type={this.props.reports.type} />)
            break
          case 'detailed-fm':
          case 'detailed-sm':
          case 'detailed-sales':
            report = (<ReportDetailed data={this.props.reports.data} type={this.props.reports.type} />)
            break;
          case 'ranking-fm':
          case 'ranking-sm':
          case 'ranking-sales':
            const rankingsOpts = {
              activeDealership: 'all' === this.props.user.dealership ? `All Dealerships` : activeDealership,
              endDate: this.props.user.endDate,
              startDate: this.props.user.startDate,
            }
            report = (<ReportRanking data={this.props.reports.data} rankingsOpts={rankingsOpts} type={this.props.reports.type} typeDictionary={reportDictionary}/>)
            break;
          default:
        }
      }
    }

    let rowClasses = ['report-filters-row']
    let peopleDropdown = null
    if (-1 !== this.props.match.params.type.search('detailed') && this.state.people) {
      rowClasses.push('three-col')
      peopleDropdown = (
        <div className="report-filters-column report-filters-column-wide">
          <Select
            onChange={this.changePerson}
            options={this.state.people}
            value={this.state.personSelected}
          />
        </div>
      )
    }

    return (
      <div id="reports">
        <section className="page-header">
          <h1>{header.title}</h1>
          <p>You are currently viewing the <em>{header.title}</em> for <strong>{activeDealership}</strong>. You can change the start and end dates below{ifCanChangeDealership}</p>
          <div className={rowClasses.join(' ')}>
            {peopleDropdown}
            <div className="report-filters-column">
              <DayPickerInput
                formatDate={format}
                format={dateFormat}
                parseDate={parse}
                placeholder="Start Date"
                dayPickerProps={{
                  disabledDays: [{
                    after: this.props.user.endDate
                  }],
                  month: new Date(),
                  selectedDays: this.props.user.startDate,
                  onDayClick: this.handleStartDayClick,
                  toMonth: new Date(),
                  showOutsideDays: true,
                  todayButton: 'Today',
                }}
                value={this.props.user.startDate}
              />
            </div>
            <div className="report-filters-column">
              <DayPickerInput
                formatDate={format}
                format={dateFormat}
                parseDate={parse}
                placeholder="End Date"
                dayPickerProps={{
                  disabledDays: [{
                    after: new Date(),
                    before: this.props.user.startDate,
                  }],
                  month: new Date(),
                  selectedDays: this.props.user.endDate,
                  onDayClick: this.handleEndDayClick,
                  toMonth: new Date(),
                  showOutsideDays: true,
                  todayButton: 'Today',
                }}
                value={this.props.user.endDate}
              />
            </div>
          </div>
        </section>
        {report}
      </div>
    )
  }

}


function mapStateToProps(state) {
  return {
    dealerships: state.dealerships,
    loading: state.loading,
    reports: state.reports,
    user: state.user
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({
    didChangeDate: didChangeDate,
    getUpdatedReport: getUpdatedReport
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Report)
